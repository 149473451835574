import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from '../../Layouts/Layout'
import PortfolioRoll from '../../modules/PortfolioRoll/PortfolioRoll'
import Title from '../../components/Title/Title'
import PageContainer from '../../components/PageContainer/PageContainer'

const PortfolioIndexPage = () => (
  <Layout>
    <Helmet title="Portfolio" />
    <Title>My Portfolio</Title>
    <PageContainer>
      <PortfolioRoll />
    </PageContainer>
  </Layout>
)

PortfolioIndexPage.propTypes = {}

export default PortfolioIndexPage
