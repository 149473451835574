import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import ArticlePreview from '../../components/ArticlePreview/ArticlePreview'

const MoreProjects = styled.p`
  color: ${({ theme }) => theme.color.muted};
  text-align: center;
`

export const PortfolioList = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark

  return (
    <>
      {posts &&
        posts.map(({ node: post }, i) => {
          const {
            frontmatter: { title, start, end, featureImage, tags },
            fields: { slug },
            excerpt,
          } = post
          return (
            <React.Fragment key={slug}>
              <ArticlePreview
                slug={slug}
                featureImage={featureImage}
                title={title}
                date={
                  start
                    ? `${start}${
                        end !== 'Invalid date' ? ` - ${end}` : ' - present'
                      }`
                    : null
                }
                tags={tags}
              >
                {excerpt}
              </ArticlePreview>
              {i + 1 !== posts.length && <hr style={{ margin: '2em 0' }} />}
            </React.Fragment>
          )
        })}
      {posts.length < 5 && (
        <MoreProjects>
          I am still uploading my past projects, please check back soon!
        </MoreProjects>
      )}
    </>
  )
}

PortfolioList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.any),
    }),
  }),
}

PortfolioList.defaultProps = {
  data: {},
}

export default () => (
  <StaticQuery
    query={graphql`query PortfolioRollQuery {
  allMarkdownRemark(
    sort: {order: DESC, fields: [frontmatter___start]}
    filter: {frontmatter: {templateKey: {eq: "portfolio"}}}
  ) {
    edges {
      node {
        excerpt(pruneLength: 300, format: HTML)
        id
        fields {
          slug
        }
        frontmatter {
          title
          templateKey
          start(formatString: "MM/YYYY")
          end(formatString: "MM/YYYY")
          tags
          codeLibraries
          featureImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`}
    render={(data, count) => <PortfolioList data={data} count={count} />}
  />
)
